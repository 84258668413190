/* .sidebar_menuItem:hover {
  color: black;
  border-radius: 18px;
} */
.sidebar_menuItem {
  color: #fff;
  background-color: #4068f3;
}
/* .sidebar_menuItem:hover {
  color: black;
  border-radius: 18px;
} */
.sidebar_subMainMenu {
  background-color: #4068f3;
}
.css-1t8x7v1 > .ps-menu-button {
  margin-left: 20px;
  font-size: 20px;
  width: 200px;
  font-weight: bold;
  /* color: #fff; */
}
/* mine Items  start*/
.css-1t8x7v1 > .ps-menu-button:hover {
  color: black;
  border-radius: 18px;
}
.css-1tqrhto > .ps-menu-button {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  width: 200px;
}
/* mine Items end*/
.css-1tqrhto > .ps-menu-button:hover {
  color: black;
  border-radius: 18px;
  background-color: #4068f3;
}
.css-16jesut > .ps-menu-button {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  width: 200px;
}
.css-16jesut > .ps-menu-button:hover {
  color: black;
  border-radius: 18px;
  background-color: #4068f3;
}

.--addUser_inputFields {
  text-align: start;
  font-size: 22px;
  font-family: "Times New Roman", Times, serif;
  padding-left: 8px;
  height: 40px;
  width: 50%;
  background: #004bcb66;
  border: none;
  border-radius: 10px;
  outline: none;
  padding-right: 5px;
}
.--materialcharge {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.534);
  width: 90%;
}
.--addUser_inputFields_dropdown {
  text-align: start;
  font-size: 14px;
  padding: 1px 5px;
  font-family: "Times New Roman", Times, serif;
  height: 40px;
  width: 50%;
  background: #004bcb66 !important;
  border: none;
  border-radius: 5px;
  outline: none;
}
.css-t3ipsp-control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.--addUser_inputFields_date {
  text-align: start;
  font-size: 20px;
  padding: 0px 15px;
  height: 40px;
  font-family: "Times New Roman", Times, serif;
  width: 100%;
  background: #004bcb66;
  border: none;
  border-radius: 10px;
  outline: none;
}
.react-datepicker-wrapper {
  width: 50% !important;
}
.transportInput {
  border-radius: 8px;
  outline: none;
  padding: 0px 5px;
  border: 1px solid rgba(0, 0, 0, 0.514);
  width: 20%;
  height: 30px !important;
  margin: 0px 5px;
}
.user-password {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}
.panCard_inputFields {
  margin-bottom: 20px;
  text-align: start;
  font-size: 20px;
  padding-left: 8px;
  height: 40px;
  width: 50%;
  background: #004bcb66;
  border: none;
  border-radius: 10px;
  outline: none;
}

.addUser_ticket_label {
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Times New Roman', Times, serif; */
}
.--addUser_companyName_inputField {
  width: 75%;
  text-align: start;
  padding-left: 8px;
  font-size: 20px;
  margin-left: 8px;
  height: 40px;
  background: #004bcb66;
  border: none;
  border-radius: 10px;
  font-family: "Times New Roman", Times, serif;
  display: flex;
  justify-content: center;
  outline: none;
}

.addUser_submitBtn_field {
  display: flex;
  justify-content: center;
}
.addUser_submitBtn {
  background-color: #4068f3;
  border: none;
  border-radius: 15px;
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}
.aadharFileInput {
  height: 40px;
  border: 2px #4068f3 dashed;
  border-radius: 10px;
  background-color: #b9d6c366;
}
.fileInput::-webkit-file-upload-button {
  visibility: hidden;
}
.ReactTags__tag {
  background-color: #f1f1f1;
  border-radius: 3px;
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
}

.ReactTags__remove {
  margin-left: 10px;
  cursor: pointer;
  background-color: #4068f3;
  border-radius: 6px;
  outline: none;
  border: none;
}

.ReactTags__tagInput {
  width: 100%;
  padding: 10px;
}
.ReactTags__tagInputField {
  width: 100%;
  text-align: start;
  padding-left: 8px !important;
  font-size: 20px;
  height: 40px;
  background: #adc6f366;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  outline: none;
}
.ReactTags__selected {
  width: 100%;
}
.modalSubmitBtn {
  background-color: #4068f3;
  width: 80px;
  border-radius: 10px;
  font-size: 22px;
  color: #fff;
  border: none;
}
.addrequest-heading {
  color: #004acbee;
  font-size: 28px;
  font-family: "Times New Roman", Times, serif;
}
.ckbox-label {
  /* color: red; */
  color: #e90000;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Times New Roman", Times, serif;
}
.model-type {
  width: 25%;
}
